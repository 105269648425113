import { BiImage } from "react-icons/bi";
import React, { useState, useRef, useEffect } from "react";
import {
  fetchOneBanner,
  adminSelector,
  updateBannerDetails,
} from "../../../api/auth";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineUser, AiOutlineClose } from "react-icons/ai";

export default function UpdateBannerForm() {
  const [bannerImage, setBannerImage] = useState({ banner_image: null }); // This will store the new image file
  const [formData, setFormData] = useState({
    banner_placement: "",
    banner_ranking: "",
    navigate_to: "",
  });

  const { one_banner } = useSelector(adminSelector);
  const { id } = useParams(); // This is the banner ID to fetch the details for
  console.log(id, "id");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [previewImage, setPreviewImage] = useState(null); // This will be used to show the image preview

  // Fetch the banner details when the component mounts
  useEffect(() => {
    dispatch(fetchOneBanner(id)); // Fetch banner details based on ID
  }, [id, dispatch]);

  // Set form data and preview image once the banner is fetched
  useEffect(() => {
    if (one_banner) {
      setFormData({
        banner_placement: one_banner.banner_placement || "",
        banner_ranking: one_banner.banner_ranking || "",
        navigate_to: one_banner.navigate_to || "",
      });
      setPreviewImage(one_banner.banner_image); // Set the preview image from the banner details
    }
  }, [one_banner]);

  // Handle file selection for the banner image
  const handleProfile = (e) => {
    const { files } = e.target;
    const file = files[0];

    // Check if a file was selected
    if (file) {
      // Update state with the selected file and set the preview image
      setBannerImage((prev) => ({ ...prev, banner_image: file }));
      setPreviewImage(URL.createObjectURL(file));
    } else {
      // Optional: handle case when no file is selected (if needed)
      console.warn("No file selected");
    }
  };

  const handleRemoveImage = () => {
    setBannerImage({ banner_image: null });
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const bannerData = {
      banner_image: bannerImage.banner_image,
      ...formData,
    };

    console.log(bannerData, "benner");
    // Dispatch the action to update the banner details
    dispatch(updateBannerDetails(id, bannerData));

    // Redirect after 3 seconds (optional)
    setTimeout(() => {
      navigate(-1); // Go back to the previous page
    }, 3000);
  };

  return (
    <div className="max-w-lg border p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        Update Banner Details
      </h2>
      <form onSubmit={handleSubmit}>
        {/* Banner Image */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Banner Image</label>
          <div className="flex items-center gap-4">
            <div className="w-32 h-32 bg-gray-100 border border-dashed border-gray-300 flex items-center justify-center">
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="Preview"
                  className="object-contain h-full w-full"
                />
              ) : (
                <span className="text-gray-400 text-sm">Select image</span>
              )}
            </div>
            <label className="cursor-pointer text-gray-400">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleProfile}
              />
              {previewImage ? (
                <span
                  onClick={handleRemoveImage}
                  className="text-red-500 cursor-pointer"
                >
                  Remove image
                </span>
              ) : (
                <span>Select image to upload</span>
              )}
            </label>
          </div>
        </div>

        {/* Banner Placement */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Banner Placement</label>
          <select
            name="banner_placement"
            value={formData.banner_placement}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none"
          >
            <option value="">Select placement</option>
            <option value="home">Home Page Banners</option>
            <option value="products">Products Page Banners</option>
            <option value="services">Services Page Banners</option>
          </select>
        </div>

        {/* Banner Ranking */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Banner Ranking</label>
          <select
            name="banner_ranking"
            value={formData.banner_ranking}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none"
          >
            <option value="">Select rank</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>

        {/* Navigate To */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Navigate To</label>
          <select
            name="navigate_to"
            value={formData.navigate_to}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none"
          >
            <option value="">Select module</option>
            <option value="module1">Module 1</option>
            <option value="module2">Module 2</option>
            <option value="module3">Module 3</option>
          </select>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-primary text-white py-2 rounded-md focus:outline-none"
        >
          Update Banner
        </button>
      </form>
    </div>
  );
}
