import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { adminLogin } from "../../api/auth";
import LoginLogo from "../../assets/logo.png";
import LoginImage from "../../assets/login_left_image.png";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    const isLogginSucessfull = await dispatch(adminLogin(formData));
    if (isLogginSucessfull) {
      setTimeout(() => {
        navigate("/dashboard");
      }, 2500);
    }
  };

  return (
    <div className="flex min-h-screen">
      <div className="w-3/5 hidden md:flex items-center justify-center bg-[#F6F0F0]">
        <div className="w-2/3 h-2/3 rounded-lg flex items-center justify-center">
          <img
            src={LoginImage}
            alt="Login Visual"
            className="w-full h-full object-contain"
          />
        </div>
      </div>

      <div className="w-full md:w-2/5 flex flex-col justify-center items-center bg-white mx-auto md:mx-0">
        <img src={LoginLogo} alt="Logo" className="mb-10 h-12" />

        <form className="w-3/4" onSubmit={handleLogin}>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 text-start"
              htmlFor="email"
            >
              Email Address
            </label>
            <TextField
              id="email"
              name="email"
              type="email"
              placeholder="Enter email address"
              variant="standard"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FaEnvelope className="text-primary" />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 text-start"
              htmlFor="password"
            >
              Password
            </label>
            <TextField
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              variant="standard"
              fullWidth
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <FaEyeSlash
                        className="text-primary cursor-pointer"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <FaEye
                        className="text-primary cursor-pointer"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <button
            type="submit"
            className="w-full bg-black text-white py-2 rounded-full hover:bg-gray-800 transition duration-300"
          >
            Log In to account
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
