import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export default function ContentDataTable({
  columns,
  rows,
  handleUpdate,
  onView,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterRows, setFilterRows] = useState(rows || []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //   {
  //     "_id": "67484a7394f62aa38d844c8f",
  //     "banner_image": "https://ayushvistorage.s3.ap-south-1.amazonaws.com/uploads/1732790898799-208672591-Ayushvi-Banner-7.png",
  //     "banner_placement": "home",
  //     "banner_ranking": 3,
  //     "navigate_to": "module1",
  //     "banner_id": "AYUBNR818194",
  //     "createdAt": "2024-11-28T10:48:19.371Z",
  //     "updatedAt": "2024-11-28T10:48:19.371Z",
  //     "__v": 0
  // }
  useEffect(() => {
    setFilterRows(rows);
  }, [rows]);

  console.log();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className="max-w-[98vw] sm:max-w-full">
      <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || "left"}
                  style={{ minWidth: column.minWidth }}
                  sx={{ padding: "8px", borderBottom: "2px solid #031E3E" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align || "left"}>
                        {column.format
                          ? column.format(value, row)
                          : value || "--"}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
