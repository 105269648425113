import React, { useEffect } from "react";
import BlogDataTable from "./BlogDataTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllBlog, adminSelector } from "../../../api/auth";

const blogData = [
  {
    id: "AYUSH123",
    uploadDate: "02/02/23",
    title: "Lorem ipsum dolor sit amet.",
    status: "active",
  },
  {
    id: "AYUSH124",
    uploadDate: "03/02/23",
    title: "Consectetur adipiscing elit.",
    status: "inactive",
  },
];

const BlogDataHome = () => {
  const { adminToken, blog } = useSelector(adminSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (adminToken) {
      dispatch(getAllBlog());
    }
  }, [adminToken, dispatch]);

  return (
    <>
      <BlogDataTable blogData={blog} />
    </>
  );
};

export default BlogDataHome;
