import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import BlogDataTable from "./components/BlogDataTable";
import DatatableSection from "../../components/datatable/DatatableSection";
import BlogDataHome from "./components/BlogDataHome";

const BlogManagement = () => {
  return (
    <>
      <Sidebar />
      <MainSection>
        <Sectionheader title="Blog Management">
          <SectionHeaderButton
            redirectPath="add-new-blog"
            label="Add New Blog"
          />
        </Sectionheader>
        <DatatableSection>
          <BlogDataHome />
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default BlogManagement;
