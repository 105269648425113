import { BiTrashAlt } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { BiEditAlt } from "react-icons/bi";
import React, { useState, useEffect } from "react";
import Tabs from "./Tabs";
import ContentDataTable from "./ContentDataTable";
import ToggleSwitch from "../../../components/toggleSwitch/ToggleSwitch";
import DatatableSection from "../../../components/datatable/DatatableSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBanner,
  adminSelector,
  updateBannerStatus,
  deleteBanner,
} from "../../../api/auth";
import { useNavigate, useParams } from "react-router-dom";

export default function BannerManagement() {
  const [activeTab, setActiveTab] = useState("home");
  const { adminToken, banner } = useSelector(adminSelector);
  const dispatch = useDispatch();
  const [homeData, setHomeData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (adminToken) {
      dispatch(getAllBanner());
    }
  }, [adminToken, dispatch]);

  const handleDelete = (bannerId) => {
    dispatch(deleteBanner(bannerId));
  };

  useEffect(() => {
    // Only update state when banner data changes
    setHomeData(banner.filter((data) => data?.banner_placement === "home"));
    setServicesData(
      banner.filter((data) => data?.banner_placement === "services")
    );
    setProductsData(
      banner.filter((data) => data?.banner_placement === "products")
    );
  }, [banner]); // Dependency on banner, not on homeData, servicesData, or productsData

  const rowsData = {
    home: homeData,
    products: productsData,
    services: servicesData,
  };

  const handleEdit = (bannerId) => {
    navigate(`/content-management/${bannerId}`);
  };

  const handleUpdate = async (bannerId, status) => {
    console.log(bannerId, status, "ABC");
    const payload = {
      bannerId,
      status,
    };

    console.log(payload, "payload");

    try {
      await dispatch(updateBannerStatus(payload));
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  const columns = [
    { id: "banner_id", label: "Banner ID", minWidth: 120 },
    { id: "createdAt", label: "Upload Date", minWidth: 120 },
    {
      id: "banner_image",
      label: "Banner Preview",
      minWidth: 150,
      align: "center",
      format: (_, row) => (
        <div className="border w-32 rounded h-16 overflow-hidden mx-auto">
          <img
            src={row?.banner_image} // Assuming `banner_image` is the URL of the image
            alt="Banner"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      ),
    },
    { id: "banner_ranking", label: "Ranking", minWidth: 100 },

    {
      id: "status",
      label: "Status",
      minWidth: 100,
      align: "center",
      format: (value, row) => (
        <ToggleSwitch
          checked={row?.status === "active"}
          onChange={() =>
            handleUpdate(
              row?._id,
              row?.status === "active" ? "inactive" : "active"
            )
          }
        />
      ),
    },
    {
      id: "action",
      label: "Action",
      minWidth: 150,
      align: "center",
      format: (_, row) => (
        <div className="flex justify-center gap-2">
          <button
            onClick={() => handleEdit(row?._id)}
            className="flex items-center gap-1 bg-black text-white px-3 py-1 rounded "
          >
            <span>
              <BiPencil />
            </span>
            Edit
          </button>
          <button
            onClick={() => handleDelete(row._id)}
            className="bg-primary text-white px-3 py-1 rounded "
          >
            <AiFillDelete />
          </button>
        </div>
      ),
    },
  ];

  const tabs = [
    { id: "home", label: "Home Banners" },
    { id: "products", label: "Product Banners" },
    { id: "services", label: "Service Banners" },
  ];
  console.log(activeTab, "active");

  return (
    <div className="p-4">
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
      <DatatableSection>
        <ContentDataTable columns={columns} rows={rowsData[activeTab]} />
      </DatatableSection>
    </div>
  );
}
