import React, { useState, useRef, useEffect } from "react";
import MainSection from "../../components/mainSection/MainSection";
import Sidebar from "../../components/sidebar/Sidebar";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import SearchBar from "../../components/searchbar/Search-Bar";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import DatatableSection from "../../components/datatable/DatatableSection";
import HealthTable from "./HealthTable";
import { fetchAllHealthAdmin, adminSelector } from "../../api/auth";
import { useDispatch, useSelector } from "react-redux";

const HealthAdmin = () => {
  const dispatch = useDispatch();
  const { adminToken, health_admin } = useSelector(adminSelector);
  const [filterAdmin, setFilterAdmin] = useState(health_admin || []);

  useEffect(() => {
    setFilterAdmin(health_admin);
  }, [health_admin]);

  console.log(adminToken, "admi token");
  console.log(health_admin, "health admin");

  useEffect(() => {
    if (adminToken) {
      dispatch(fetchAllHealthAdmin(adminToken));
    }
  }, [dispatch]);

  return (
    <div className="font-semibold">
      {/* Sidebar */}
      <Sidebar />

      <MainSection>
        <Sectionheader title="Health Admins">
          <SectionHeaderButton
            redirectPath="/health-admins/create-health-admin"
            label="Create Health Admin"
          />
        </Sectionheader>
        <div className=" bg-white">
          <SearchBar
            label1="Filter"
            label2="Export"
            data={filterAdmin}
            onSearch={setFilterAdmin}
            fileName="health_admin.csv"
          />
        </div>

        <DatatableSection>
          <HealthTable filterAdmin={filterAdmin} />
        </DatatableSection>
      </MainSection>
    </div>
  );
};

export default HealthAdmin;
