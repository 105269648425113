import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import CreateNewDoctor from "./pages/doctors/CreateNewDoctor";
import Doctors from "./pages/doctors/Doctors";
import NotFound from "./pages/404Error/NotFound";
import ProductsManagement from "./pages/productsManagement/ProductsManagement";
import CreateNewProduct from "./pages/productsManagement/CreateNewProduct";
import HealthAdmin from "./pages/healthAdmin/HealthAdmin";
import PharmaAdmin from "./pages/pharmaAdmin/PharmaAdmin";
import Patient from "./pages/patients/Patients";
import CreateHealthAdmin from "./pages/healthAdmin/CreateHealthAdmin";
import CreatePharmaAdmin from "./pages/pharmaAdmin/CreatePharmaAdmin";
import UpdateHealthAdmin from "./pages/healthAdmin/updateHealthAdmin";
import UpdateDoctor from "./pages/doctors/updateDoctor";
import UpdatePharmaAdmin from "./pages/pharmaAdmin/updatePharmaAdmin";
import ContentManagement from "./pages/contentManagement/ContentManagement";
import AddBanner from "./pages/contentManagement/AddBanner";
import BlogManagement from "./pages/blogManagement/BlogManagement";
import AddBlog from "./pages/blogManagement/AddBlog";
import EditBlog from "./pages/blogManagement/EditBlog";
import EditBanner from "./pages/contentManagement/Editbanner";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="flex">
          <main className="flex-1">
            {/* Define the routes here */}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />

              {/* Doctor */}
              <Route path="/doctors" element={<Doctors />} />
              <Route
                path="/doctors/create-new-doctor"
                element={<CreateNewDoctor />}
              />

              {/* Health Admin */}
              <Route path="/doctors/update/:id" element={<UpdateDoctor />} />
              <Route
                path="/health_admin/update/:id"
                element={<UpdateHealthAdmin />}
              />

              {/* Pharma Admin */}
              <Route
                path="/pharma_admin/update/:id"
                element={<UpdatePharmaAdmin />}
              />

              {/* Product Management */}
              <Route
                path="/products-management"
                element={<ProductsManagement />}
              />
              <Route
                path="/products-management/create-new-product"
                element={<CreateNewProduct />}
              />

              {/* Health Admin */}
              <Route path="/health-admins" element={<HealthAdmin />} />

              <Route
                path="/health-admins/create-health-admin"
                element={<CreateHealthAdmin />}
              />

              {/* Pharma Admin */}
              <Route path="/pharma-admins" element={<PharmaAdmin />} />
              <Route
                path="/pharma-admins/create-pharma-admin"
                element={<CreatePharmaAdmin />}
              />

              {/* Patients */}
              <Route path="/patients" element={<Patient />} />

              {/* Product Management */}
              <Route
                path="/products-management"
                element={<ProductsManagement />}
              />
              <Route
                path="/products-management/create-new-product"
                element={<CreateNewProduct />}
              />

              {/* Content Management */}
              <Route
                path="/content-management"
                element={<ContentManagement />}
              />
              <Route
                path="/content-management/add-new-banner"
                element={<AddBanner />}
              />
              <Route path="/content-management/:id" element={<EditBanner />} />

              {/* Content Management */}
              <Route path="/blog-management" element={<BlogManagement />} />
              <Route
                path="/blog-management/add-new-blog"
                element={<AddBlog />}
              />
              <Route
                path="/blog-management/edit-blog/:blogID"
                element={<EditBlog />}
              />

              {/* 404 Route - this should be the last route */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </div>
      </Router>
    </div>
  );
}

export default App;
