import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import BlogForm from "./components/BlogForm";
import EditBlogHome from "./components/EditBlogHome";


const EditBlog = () => {

  

  return (
    <>
      <Sidebar />
      <MainSection>
        <Sectionheader title="Edit Blog">
          <SectionHeaderButton
            redirectPath="/blog-management"
            label="Go Back"
          />
        </Sectionheader>
        <EditBlogHome />
      </MainSection>
    </>
  );
};

export default EditBlog;
