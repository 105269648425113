import { BsFilterRight } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import { saveAs } from "file-saver";
import React, { useState, useRef, useEffect } from "react";

const SearchBar = ({ label1, label2, data, fileName, onSearch }) => {
  console.log(data, "data");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");

  const handleExport = () => {
    if (!data || data.length === 0) {
      console.error("No data available for export.");
      return;
    }

    // Generate CSV headers
    const csvHeaders = Object.keys(data[0]).join(",") + "\n";

    // Generate CSV rows
    const csvRows = data.map((row) =>
      Object.values(row)
        .map((value) => `"${value}"`) // Enclose values in quotes for compatibility
        .join(",")
    );

    // Combine headers and rows
    const csvContent = csvHeaders + csvRows.join("\n");

    // Create Blob for download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, fileName);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 300); // Debounce delay of 300ms

    return () => {
      clearTimeout(handler); // Cleanup debounce timeout on input change
    };
  }, [searchTerm]);

  // Update filtered results when the debounced term changes
  useEffect(() => {
    if (!debouncedTerm.trim()) {
      // If input is cleared, return the full dataset
      onSearch(data);
    } else {
      const filteredData = data.filter((item) => {
        // Check against both `admin_name` and `name`
        const searchField = item?.admin_name || item?.name;
        return searchField?.toLowerCase().includes(debouncedTerm.toLowerCase());
      });
      onSearch(filteredData); // Pass filtered data to the parent
    }
  }, [debouncedTerm, data, onSearch]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term as user types
  };

  // const handleInputChange = (e) => {
  //   const searchTerm = e.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive search
  //   setSearchTerm(searchTerm);

  //   // Check if the search term is empty; if yes, reset to full dataset
  //   if (!searchTerm.trim()) {
  //     onSearch(data); // Pass the full dataset when the input is cleared
  //     return;
  //   }

  //   // Filter the data based on whether it contains `admin_name` or `name`
  //   const filteredData = data.filter((item) => {
  //     const searchField = item.admin_name || item.name; // Use admin_name if available, otherwise name
  //     return searchField?.toLowerCase().includes(searchTerm); // Perform case-insensitive match
  //   });

  //   onSearch(filteredData); // Pass filtered data to the parent
  // };

  return (
    <div className="block md:flex justify-between items-center py-4">
      <div className="max-w-md w-full mb-4">
        <form className="max-w-md w-full ">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-[#681312]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg focus:outline-none"
              placeholder="Search"
              value={searchTerm}
              onChange={handleInputChange}
              required
            />
            <button
              type="submit"
              className="text-white absolute end-2.5 bottom-2.5 bg-[#681312] hover:bg-[#902d2b] focus:outline-none font-medium rounded-lg text-sm px-4 py-2"
            >
              Search
            </button>
          </div>
        </form>
      </div>
      <div className="flex gap-4 mb-4">
        <div className="flex">
          <button className="flex m-auto px-4 py-2 border text-black rounded-md w-full">
            <BsFilterRight className="mr-2 my-auto" />
            <div>Filter</div>
          </button>
        </div>
        <div className="flex">
          <button className="flex m-auto px-4 py-2 border text-black rounded-md w-full">
            <CiExport className="mr-2 my-auto" />
            <div onClick={handleExport} className="item-center">
              Export
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
