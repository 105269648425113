import { AiOutlineUser, AiOutlineClose } from "react-icons/ai";
import React, { useState, useRef, useEffect } from "react";
import MainSection from "../../components/mainSection/MainSection";
import Sidebar from "../../components/sidebar/Sidebar";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import { useNavigate } from "react-router-dom";
import { addDoctor } from "../../api/doctor";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import { FaEnvelope, FaEye, FaEyeSlash } from "react-icons/fa";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ToggleSwitch from "../../components/toggleSwitch/ToggleSwitch";
import { addpharmaAdmin } from "../../api/auth";

const CreatePharmaAdmin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const { success } = useSelector((state) => state.doctor);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({ profileImage: null });
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password === formData.confirm_password) {
      const doctorData = {
        ...formData,
        profileImage: profile.profileImage,
      };
      dispatch(addpharmaAdmin(doctorData));
      setTimeout(() => {
        navigate(-1); // Navigate back after the delay
      }, 3000);
    }
  };

  console.log(formData, "form");
  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleProfile = (e) => {
    const { files } = e.target;
    const file = files[0];

    // Check if a file was selected
    if (file) {
      // Update state with the selected file and set the preview image
      setProfile((prev) => ({ ...prev, profileImage: file }));
      setPreviewImage(URL.createObjectURL(file));
    } else {
      // Optional: handle case when no file is selected (if needed)
      console.warn("No file selected");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "password") {
      // Check password match only when typing in the password field
      if (value !== formData.confirm_password) {
        setPasswordError("Passwords do not match.");
      } else {
        setPasswordError("");
      }
    }

    if (name === "confirm_password") {
      // Check password match only when typing in the confirm password field
      if (formData.password !== value) {
        setPasswordError("Passwords do not match.");
      } else {
        setPasswordError("");
      }
    }
  };

  const handleRemoveImage = () => {
    setPreviewImage(null);
    setFormData((prev) => ({ ...prev, profileImage: null }));
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (success) {
      setFormData({
        name: "",
        email: "",
        password: "",
        confirm_password: "",
        profileImage: null,
      });
      setPreviewImage(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [success]);

  return (
    <div className=" font-semibold">
      <Sidebar />
      {/* <Sidebar onNavigate={handleNavigation} /> */}

      <MainSection>
        <Sectionheader title="Create New Pharma Admin"></Sectionheader>
        <div className=" bg-white">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
            {/* Left Section */}
            <div className="space-y-6 sm:border-r pr-10">
              <h2 className="text-lg text-red-800">Basic Details</h2>
              <div className="flex items-center space-x-4">
                <div className="relative w-16 h-16 rounded border bg-gray-100 flex items-center justify-center overflow-hidden">
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Profile Preview"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <AiOutlineUser className="h-1/2 w-auto text-gray-400" />
                  )}
                  {previewImage && (
                    <button
                      onClick={handleRemoveImage}
                      className="absolute top-1 right-1 bg-white p-1 rounded-full hover:bg-red-500 hover:text-white transition-colors"
                      title="Remove Image"
                    >
                      <AiOutlineClose className="text-gray-500" />
                    </button>
                  )}
                </div>
                <div className="flex flex-col items-start">
                  <input
                    type="file"
                    id="fileUpload"
                    className="hidden"
                    accept="image/*"
                    onChange={handleProfile}
                    ref={fileInputRef}
                  />
                  <label
                    htmlFor="fileUpload"
                    className="cursor-pointer text-gray-400 px-4 py-2 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-blue-300"
                  >
                    Upload Profile Picture
                  </label>
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Pharma Admin Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                    placeholder="Enter pharma admin name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                    placeholder="Enter email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5 pr-10"
                      placeholder="Enter password"
                      required
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="confirm_password"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm_password"
                      name="confirm_password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5 pr-10"
                      placeholder="Enter password"
                      required
                      value={formData.confirm_password}
                      onChange={handleChange}
                    />
                    <div
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </div>
                    {passwordError ? (
                      <p className="text-red-500 text-sm">{passwordError}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-6">
              <h2 className="text-lg text-red-800">Pharma Admin Access</h2>

              <div>
                <label for="" class="block mb-2 text-sm  text-gray-900 ">
                  Access Role
                </label>
                <div class="flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none w-full p-2.5 mb-3">
                  <span>Access Name</span>
                  <ToggleSwitch />
                </div>

                <div class="flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none w-full p-2.5 mb-3">
                  <span>Access Name</span>
                  <ToggleSwitch />
                </div>

                <div class="flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none w-full p-2.5 mb-3">
                  <span>Access Name</span>
                  <ToggleSwitch />
                </div>

                <div class="flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none w-full p-2.5 mb-3">
                  <span>Access Name</span>
                  <ToggleSwitch />
                </div>

                <div class="flex justify-between items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none w-full p-2.5 mb-3">
                  <span>Access Name</span>
                  <ToggleSwitch />
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-6 mb-6"></hr>
          <button
            className="px-4 py-2 bg-[#681312] text-white rounded-md"
            onClick={handleSubmit}
          >
            Save Details
          </button>
        </div>
      </MainSection>
    </div>
  );
};

export default CreatePharmaAdmin;
