import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import BannerTable from "./components/BannerTable";
import MainSection from "../../components/mainSection/MainSection";
import BannerManagement from "./components/BannerManagement";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatable/DatatableSection";

const ContentManagement = () => {
  return (
    <>
      <Sidebar />
      <MainSection>
        <Sectionheader title="Content management">
          <SectionHeaderButton
            redirectPath="add-new-banner"
            label="Add New Banner"
          />
        </Sectionheader>
        <BannerManagement />
      </MainSection>
    </>
  );
};

export default ContentManagement;
