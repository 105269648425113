import { BiImage } from "react-icons/bi";
import React, { useState, useRef } from "react";
import ReactQuill from "react-quill"; // Import Quill component
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { addBlog } from "../../../api/auth";
import { useDispatch } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function BlogForm() {
  const [blogImage, setBlogImage] = useState({ blog_image: null });
  const [formData, setFormData] = useState({
    blog_title: "",
    description: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);

  // Handle changes for standard form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle changes for ReactQuill (description field)
  const handleQuillChange = (value) => {
    setFormData((prev) => ({ ...prev, description: value }));
  };

  const handleProfile = (e) => {
    const { files } = e.target;
    const file = files[0];

    // Check if a file was selected
    if (file) {
      // Update state with the selected file and set the preview image
      setBlogImage((prev) => ({ ...prev, blog_image: file }));
      setPreviewImage(URL.createObjectURL(file));
    } else {
      // Optional: handle case when no file is selected (if needed)
      console.warn("No file selected");
    }
  };

  const handleRemoveImage = () => {
    setFormData((prev) => ({ ...prev, profileImage: null }));
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      blog_image: blogImage.blog_image,
    };
    dispatch(addBlog(data));
    setTimeout(() => {
      navigate(-1); // Navigate back after the delay
    }, 3000);
  };

  return (
    <div className="border max-w-3xl p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        Basic Details
      </h2>
      <form onSubmit={handleSubmit}>
        {/* Blog Image */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Blog Image</label>
          <div className="flex items-center gap-4">
            <div className="w-32 h-32 bg-gray-100 border border-dashed border-gray-300 flex items-center justify-center">
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="Preview"
                  className="object-contain h-full w-full"
                />
              ) : (
                <span className="text-gray-400 text-sm">Select image</span>
              )}
            </div>
            <label className="cursor-pointer text-gray-400">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleProfile}
              />
              {previewImage ? (
                <span
                  onClick={handleRemoveImage}
                  className="text-red-500 cursor-pointer"
                >
                  Remove image
                </span>
              ) : (
                <span>Select image to upload</span>
              )}
            </label>
          </div>
        </div>

        {/* Blog Title */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Blog Title</label>
          <input
            value={formData.blog_title}
            name="blog_title"
            onChange={handleChange}
            type="text"
            placeholder="Enter blog title"
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Description (Quill Editor) */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Description</label>
          <ReactQuill
            className="h-80 pb-12"
            value={formData.description}
            name="description"
            onChange={handleQuillChange} // Changed here to use a different handler
            theme="snow" // You can choose other themes like "bubble"
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline"],
                ["link"],
                [{ align: [] }],
                ["image"],
                ["clean"],
              ],
            }}
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-primary text-white py-2 rounded-md focus:outline-none"
        >
          Upload Blog
        </button>
      </form>
    </div>
  );
}
