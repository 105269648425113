import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import BannerTable from "./components/BannerTable";
import MainSection from "../../components/mainSection/MainSection";
import BannerManagement from "./components/BannerManagement";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import BannerForm from "./components/BannerForm";

const AddBanner = () => {
  return (
    <>
      <Sidebar />
      <MainSection>
        <Sectionheader title="Add Banner">
          <SectionHeaderButton
            redirectPath="/content-management"
            label="Go Back"
          />
        </Sectionheader>
        <BannerForm />
      </MainSection>
    </>
  );
};

export default AddBanner;
