import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import BlogForm from "./components/BlogForm";

const AddBlog = () => {
  return (
    <>
      <Sidebar />
      <MainSection>
        <Sectionheader title="Add Blog">
          <SectionHeaderButton
          redirectPath="/blog-management"
          label="Go Back" />
        </Sectionheader>
        <BlogForm />
      </MainSection>
    </>
  );
};

export default AddBlog;
