import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOnePharmaAdmin,
  adminSelector,
  updatePharmaAdmin,
} from "../../api/auth";
import { AiOutlineUser, AiOutlineClose } from "react-icons/ai";
import { Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Sidebar from "../../components/sidebar/Sidebar";
import MainSection from "../../components/mainSection/MainSection";
import Sectionheader from "../../components/sectionHeader/SectionHeader";

const { Option } = Select;

const UpdatePharmaAdmin = () => {
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);
  const [profile, setProfile] = useState({ profileImage: null });
  const { one_pharma_admin } = useSelector(adminSelector);
  const [formData, setFormData] = useState({
    admin_name: "",
    email: "",
  });
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch data when component mounts
  useEffect(() => {
    if (id) {
      dispatch(fetchOnePharmaAdmin(id)); // Fetch one health admin details
    }
  }, [id, dispatch]);

  console.log(one_pharma_admin, "one");
  // Populate form with fetched admin data
  useEffect(() => {
    if (one_pharma_admin) {
      setFormData({
        name: one_pharma_admin?.name || "",
        email: one_pharma_admin?.email || "",
      });

      // If profile image exists, set it in preview
      if (one_pharma_admin?.profileImage) {
        setPreviewImage(one_pharma_admin.profileImage);
      }
    }
  }, [one_pharma_admin]);

  // Handle profile image change
  const handleProfile = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (file) {
      setProfile({ profileImage: file });
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  //   // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const adminData = {
      ...formData,
      profileImage: profile?.profileImage,
    };
    console.log(adminData, "admindata");
    dispatch(updatePharmaAdmin(id, adminData));
    setTimeout(() => {
      navigate(-1);
    }, 3000);
  };

  console.log(profile?.profileImage, "profile");

  return (
    <div className="font-semibold">
      <Sidebar />
      <MainSection>
        <Sectionheader title="Update Health Admin" />
        <div className="bg-white">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
            {/* Admin Information */}
            <div className="space-y-6 sm:border-r pr-10">
              <h2 className="text-lg text-red-800">Basic Details</h2>
              <div className="flex items-center space-x-4">
                <div className="relative w-16 h-16 rounded border bg-gray-100 flex items-center justify-center overflow-hidden">
                  {previewImage ? (
                    <img
                      src={previewImage}
                      alt="Profile Preview"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <AiOutlineUser className="h-1/2 w-auto text-gray-400" />
                  )}
                  {previewImage && (
                    <button
                      className="absolute top-1 right-1 bg-white p-1 rounded-full hover:bg-red-500 hover:text-white transition-colors"
                      title="Remove Image"
                      onClick={() => setPreviewImage(null)}
                    >
                      <AiOutlineClose className="text-gray-500" />
                    </button>
                  )}
                </div>
                <div className="flex flex-col items-start">
                  <input
                    type="file"
                    id="fileUpload"
                    className="hidden"
                    accept="image/*"
                    onChange={handleProfile}
                    ref={fileInputRef}
                  />
                  <label
                    htmlFor="fileUpload"
                    className="cursor-pointer text-gray-400 px-4 py-2 rounded-lg hover:bg-gray-100"
                  >
                    Upload Profile Picture
                  </label>
                </div>
              </div>

              {/* Admin Name and Email */}
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Health Admin Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                    value={formData?.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm text-gray-900"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-gray-400 outline-none block w-full p-2.5"
                    value={formData?.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <hr className="mt-6 mb-6"></hr>
          <button
            className="px-4 py-2 bg-[#681312] text-white rounded-md"
            onClick={handleSubmit}
          >
            Update Details
          </button>
        </div>
      </MainSection>
    </div>
  );
};

export default UpdatePharmaAdmin;
