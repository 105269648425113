import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { keyUri, config } from "../key";
import { fetchAllDoctors } from "./auth";
const token = localStorage.getItem("token") || null;

// Initial state for adding a doctor
const initialState = {
  isAuthenticate: !!token,
  loading: false,
  success: false,
  error: null,
  admin: null,
  doctor: null,
};

export const doctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {
    addDoctorRequest: (state) => {
      state.loading = true;
    },
    getLocalStorageToken: (state, { payload }) => {
      state.doctorToken = payload;
      state.isAuthenticate = true;
      state.loading = false;
    },
    // Handle authentication error
    isAuthenticateError: (state) => {
      state.loading = false;
      state.isAuthenticate = false;
      state.admin = null;
    },
    addDoctorSuccess: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      toast.success("Doctor added successfully!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
    addDoctorFailure: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
      toast.error(payload, {
        position: "top-center",
        autoClose: 2000,
      });
    },

    addAdminSuccess: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      toast.success("Doctor added successfully!", {
        position: "top-center",
        autoClose: 1000,
      });
    },
    addAdminFailure: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
      toast.error(payload, {
        position: "top-center",
        autoClose: 2000,
      });
    },
  },
});

export const {
  addDoctorRequest,
  addDoctorSuccess,
  addDoctorFailure,
  addAdminSuccess,
  addAdminFailure,
  getLocalStorageToken,
  isAuthenticateError,
} = doctorSlice.actions;

export const doctorSelector = (state) => state.doctor;

export default doctorSlice.reducer;

export const addDoctor = (doctorData) => async (dispatch) => {
  dispatch(addDoctorRequest());

  try {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.post(
      keyUri.BACKEND_URI + "/admin/add_doctor",
      doctorData,
      config
    );

    dispatch(addDoctorSuccess(data));
    dispatch(fetchAllDoctors());
  } catch (error) {
    dispatch(
      addDoctorFailure(error.response?.data?.message || "Error adding doctor")
    );
  }
};

// export const addSuperadmin = (adminData) => async (dispatch) => {
//   dispatch(addDoctorRequest());

//   try {
//     const token = localStorage.getItem("token");

//     const config = {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${token}`,
//       },
//     };

//     const { data } = await axios.post(
//       keyUri.BACKEND_URI + "/admin/add_health_admin",
//       adminData,
//       config
//     );

//     dispatch(addAdminSuccess(data));
//   } catch (error) {
//     dispatch(
//       addAdminFailure(error.response?.data?.message || "Error adding doctor")
//     );
//   }
// };
