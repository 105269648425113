import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill"; // Import Quill component
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOneBlog,
  adminSelector,
  updateBlogDetails,
} from "../../../api/auth";

export default function EditBlogHome() {
  const [blogImage, setBlogImage] = useState({ blog_image: null });
  const [formData, setFormData] = useState({
    blog_title: "",
    description: "",
  });

  const { one_blog } = useSelector(adminSelector);
  const { blogID } = useParams(); // This is the banner ID to fetch the details for
  console.log(blogID, "id");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const quillRef = useRef(null);

  const [previewImage, setPreviewImage] = useState(null); // This will be used to show the image preview

  // Fetch the banner details when the component mounts
  useEffect(() => {
    dispatch(fetchOneBlog(blogID)); // Fetch banner details based on ID
  }, [blogID, dispatch]);

  // Set form data and preview image once the banner is fetched
  useEffect(() => {
    if (one_blog) {
      setFormData({
        blog_title: one_blog.blog_title || "",
        description: one_blog.description || "",
      });
      setPreviewImage(one_blog.blog_image); // Set the preview image from the blog details
    }
  }, [one_blog]);
  // Handle file selection for the banner image
  const handleProfile = (e) => {
    const { files } = e.target;
    const file = files[0];

    // Check if a file was selected
    if (file) {
      // Update state with the selected file and set the preview image
      setBlogImage((prev) => ({ ...prev, blog_image: file }));
      setPreviewImage(URL.createObjectURL(file));
    } else {
      // Optional: handle case when no file is selected (if needed)
      console.warn("No file selected");
    }
  };
  console.log(formData, "formdata");
  const handleRemoveImage = () => {
    setBlogImage({ blog_image: null });
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (quillRef.current && formData.description) {
      const quill = quillRef.current.getEditor(); // Get the Quill instance
      quill.clipboard.dangerouslyPasteHTML(formData?.description); // Inject HTML content
    }
  }, [formData.description]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleQuillChange = (value) => {
    setFormData((prev) => ({ ...prev, description: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const blogData = {
      blog_image: blogImage.blog_image,
      ...formData,
    };

    console.log(blogData, "blog");
    // Dispatch the action to update the banner details
    dispatch(updateBlogDetails(blogID, blogData));

    // Redirect after 3 seconds (optional)
    setTimeout(() => {
      navigate(-1); // Go back to the previous page
    }, 3000);
  };

  return (
    <div className="border max-w-3xl p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        Basic Details
      </h2>
      <form onSubmit={handleSubmit}>
        {/* Blog Image */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Blog Image</label>
          <div className="flex items-center gap-4">
            <div className="w-32 h-32 bg-gray-100 border border-dashed border-gray-300 flex items-center justify-center">
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="Preview"
                  className="object-contain h-full w-full"
                />
              ) : (
                <span className="text-gray-400 text-sm">Select image</span>
              )}
            </div>
            <label className="cursor-pointer text-gray-400">
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleProfile}
              />
              {previewImage ? (
                <span
                  onClick={handleRemoveImage}
                  className="text-red-500 cursor-pointer"
                >
                  Remove image
                </span>
              ) : (
                <span>Select image to upload</span>
              )}
            </label>
          </div>
        </div>

        {/* Blog Title */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Blog Title</label>
          <input
            type="text"
            value={formData.blog_title}
            name="blog_title"
            onChange={handleChange}
            placeholder="Enter blog title"
            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Description (Quill Editor) */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Description</label>
          <ReactQuill
            className="h-80 pb-12"
            value={formData.description}
            ref={quillRef} // Reference for accessing Quill instance
            name="description"
            onChange={handleQuillChange}
            theme="snow" // You can choose other themes like "bubble"
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["bold", "italic", "underline"],
                ["link"],
                [{ align: [] }],
                ["image"],
                ["clean"],
              ],
            }}
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-primary text-white py-2 rounded-md focus:outline-none"
        >
          Upload Blog
        </button>
      </form>
    </div>
  );
}
