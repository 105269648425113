import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import ToggleSwitch from "../../components/toggleSwitch/ToggleSwitch";
import ViewButton from "../../components/viewButton/ViewButton";
import { updatePharmaAdminStatus } from "../../api/auth";
import { useDispatch } from "react-redux"; // Use redux to dispatch actions

const columns = [
  { id: "pharmaID", label: "Pharma ID", minWidth: 120 },
  { id: "creationDate", label: "Creation Date", minWidth: 120 },
  { id: "pharmaAdminName", label: "Pharma Admin Name", minWidth: 150 },
  { id: "emailAddress", label: "Email Address", minWidth: 150 },
  { id: "informationName", label: "Information Name", minWidth: 150 },
  { id: "informationName", label: "Information Name", minWidth: 150 },
  {
    id: "accStatus",
    label: "Account Status",
    minWidth: 100,
    align: "center",
    format: (value) => <ToggleSwitch checked={value === "active"} />,
  },
  {
    id: "action",
    label: "Action",
    minWidth: 100,
    align: "center",
    format: () => <ViewButton />,
  },
];

export default function PharmaTable({ filterPharmaAdmin }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleUpdate = async (pharmaAdminId, newStatus) => {
    console.log(pharmaAdminId, newStatus, "new status");
    const data = {
      pharmaAdminId,
      account_status: newStatus,
    };
    console.log(data, "data");

    try {
      await dispatch(updatePharmaAdminStatus(data));
      // Optionally, show success feedback (toast or alert)
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleViewButtonClick = (adminID) => {
    navigate(`/pharma_admin/update/${adminID}`);
  };

  return (
    <div className="max-w-[98vw] sm:max-w-full">
      <TableContainer sx={{ maxHeight: 440, maxWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sx={{ padding: "8px", borderBottom: "2px solid #031E3E" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterPharmaAdmin
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((admin) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={admin._id}>
                    <TableCell>{admin.pharmaAdminId}</TableCell>
                    <TableCell>
                      {new Date(admin.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{admin.name}</TableCell>

                    <TableCell align="center">{admin.email}</TableCell>
                    <TableCell align="center">{admin.info || "--"}</TableCell>
                    <TableCell align="center">{admin.info || "--"}</TableCell>

                    <TableCell align="center">
                      <ToggleSwitch
                        checked={admin.account_status === "active"} // Check if the doctor is active, then the switch is ON
                        onChange={() =>
                          handleUpdate(
                            admin._id,
                            admin.account_status === "active"
                              ? "inactive"
                              : "active"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ViewButton
                        onClick={() => handleViewButtonClick(admin._id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filterPharmaAdmin.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
