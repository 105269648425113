import React, { useState, useRef, useEffect } from "react";
import MainSection from "../../components/mainSection/MainSection";
import Sidebar from "../../components/sidebar/Sidebar";
import Sectionheader from "../../components/sectionHeader/SectionHeader";
import SearchBar from "../../components/searchbar/Search-Bar";
import SectionHeaderButton from "../../components/sectionHeader/SectionHeaderButton";
import DatatableSection from "../../components/datatable/DatatableSection";
import DoctorsTable from "./DoctorsTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllDoctors, adminSelector } from "../../api/auth";

const Doctors = () => {
  const dispatch = useDispatch();
  const { adminToken, doctors } = useSelector(adminSelector);
  useEffect(() => {
    if (adminToken) {
      dispatch(fetchAllDoctors());
    }
  }, [adminToken, dispatch]);
  console.log(doctors, "doctors");
  console.log(adminToken, "admi ttoken");
  const [filteredDoctors, setFilteredDoctors] = useState(doctors || []);

  useEffect(() => {
    setFilteredDoctors(doctors); // Initialize filteredDoctors with the full list
  }, [doctors]);

  return (
    <div className="font-semibold">
      {/* Sidebar */}
      <Sidebar />

      <MainSection>
        <Sectionheader title="Doctors">
          <SectionHeaderButton
            redirectPath="/doctors/create-new-doctor"
            label="Create New Doctor"
          />
        </Sectionheader>
        <div className=" bg-white">
          <SearchBar
            label1="Filter"
            label2="Export"
            data={filteredDoctors}
            fileName="doctors_list.csv"
            onSearch={setFilteredDoctors} // Update filteredDoctors on search
          />
        </div>

        <DatatableSection>
          <DoctorsTable filteredDoctors={filteredDoctors} />
        </DatatableSection>
      </MainSection>
    </div>
  );
};

export default Doctors;
